import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarService } from '@app/_shared/snack-bars/snack-bar.service';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandler implements ErrorHandler {
  private hasActiveSnackBar = false;
  private snackBarRef: MatSnackBarRef<any>;

  constructor(private injector: Injector) {}

  handleError(error) {
    if (!environment.production) {
      console.error(error);
    }

    Sentry.captureException(error.originalError || error);

    const snackBar = this.injector.get(SnackBarService);

    const isAuthError = error.error === 'login_required';
    const isIncompatibleError = !error.error || !Array.isArray(error.error.errors);

    if (!this.hasActiveSnackBar && !isAuthError && isIncompatibleError) {
      this.hasActiveSnackBar = true;

      this.snackBarRef = snackBar.openFailure({
        header: 'Det gik helt galt...',
        body:
          'Venligst reload din browser og prøv igen. Fortsætter problemet så kontakt supporten på ' +
          'support@storebuddy.dk. Vi beklager ulejligheden.',
      });

      this.snackBarRef.afterDismissed().subscribe(() => (this.hasActiveSnackBar = false));
    }
  }
}
