import { Action, createReducer, on } from '@ngrx/store';
import { ApiCallState, DataObject, RequestState } from '@app/_shared/interfaces';
import * as FeatureFlagsActions from './feature-flags.actions';
import { FeatureFlag } from '@app/_shared/interfaces/http/companies/feature-flag';

export interface State {
  companyFeatureFlags: DataObject<FeatureFlag>[];
  requestStates: {
    companyFeatureFlags: ApiCallState;
  };
}

const initialState: State = {
  companyFeatureFlags: [],
  requestStates: {
    companyFeatureFlags: RequestState.INIT,
  },
};

const featureReducer = createReducer(
  initialState,
  on(
    FeatureFlagsActions.loadCompanyFeatureFlags,
    (state): State => ({
      ...state,
      requestStates: {
        ...state.requestStates,
        companyFeatureFlags: RequestState.LOADING,
      },
    }),
  ),
  on(
    FeatureFlagsActions.loadCompanyFeatureFlagsSuccess,
    (state, action): State => ({
      ...state,
      companyFeatureFlags: action.response.data,
      requestStates: {
        ...state.requestStates,
        companyFeatureFlags: RequestState.INIT,
      },
    }),
  ),
  on(
    FeatureFlagsActions.loadCompanyFeatureFlagsFailed,
    (state, action): State => ({
      ...state,
      companyFeatureFlags: [],
      requestStates: {
        ...state.requestStates,
        companyFeatureFlags: action.error,
      },
    }),
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return featureReducer(state, action);
}
