import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { DataList } from '@app/_shared/interfaces';
import { FeatureFlag } from '@app/_shared/interfaces/http/companies/feature-flag';

@Injectable({
  providedIn: 'root',
})
export class CompanyFeatureFlagsService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/feature-flags/company';

  constructor(private _http: HttpClient) {}

  index() {
    return this._http.get<DataList<FeatureFlag, any>>(this.endpoint, { headers: this.headers });
  }
}
