import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as subscriptionActions from './subscription.actions';
import { of, switchMap } from 'rxjs';
import { SubscriptionsService } from '@app/_shared/service';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { SnackBarService } from '@app/_shared/snack-bars/snack-bar.service';

@Injectable()
export class SubscriptionEffects {
  updateSubscription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(subscriptionActions.updateSubscription),
      switchMap((action) =>
        this.subscriptionService.patch(action.data).pipe(
          map((response) => subscriptionActions.updateSubscriptionSuccess({ response: response.data })),
          catchError((error) => of(subscriptionActions.updateSubscriptionFailed({ error: error.error }))),
        ),
      ),
    );
  });

  updateSubscriptionNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(subscriptionActions.updateSubscriptionSuccess, subscriptionActions.createSubscriptionSuccess),
        tap(() => {
          this.snackBarService.openSucceeded({ body: `Dit abonnement er opdateret` });
        }),
      );
    },
    { dispatch: false },
  );

  createSubscription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(subscriptionActions.createSubscription),
      switchMap((action) =>
        this.subscriptionService.post(action.data).pipe(
          map((response) => subscriptionActions.createSubscriptionSuccess({ response: response.data })),
          catchError((error) => of(subscriptionActions.createSubscriptionFailed({ error: error.error }))),
        ),
      ),
    );
  });

  goToConfirmationUrlOnUpdateSubscriptionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(subscriptionActions.updateSubscriptionSuccess, subscriptionActions.createSubscriptionSuccess),
        filter(
          (action) =>
            action.response.attributes.billing_driver === 'shopify' &&
            action.response.attributes.confirmation_url !== null,
        ),
        tap((action) => (window.location.href = action.response.attributes.confirmation_url)),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private subscriptionService: SubscriptionsService,
    private snackBarService: SnackBarService,
  ) {}
}
