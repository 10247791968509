import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { MainState } from './main-store/main-state';

@Component({
  selector: 'sb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public store: Store<MainState>,
    private _router: Router,
    private _window: Window,
  ) {}

  ngOnInit() {
    this.initiateStripe();
  }

  initiateStripe() {
    (this._window as any).stripe = window.Stripe(environment.stripePubKey);
    (this._window as any).elements = window.stripe.elements();
  }
}
