@if ((defaultStore$ | async) === undefined) {
  <div
    class="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-z-10"
    matTooltip="Vælg en forretning for at aktivere søg"
    [matTooltipDisabled]="!!(defaultStore$ | async)"
  ></div>
}

<form class="tw-flex tw-grow tw-relative">
  @if (!showEnterIndicator && !searching) {
    <fa-icon
      [icon]="['fal', 'magnifying-glass']"
      size="xs"
      class="tw-absolute tw-top-1/2 tw-right-3 -tw-translate-y-1/2 tw-text-gray-500"
    ></fa-icon>
  }
  @if (showEnterIndicator && !searching) {
    <fa-icon
      [icon]="['fal', 'arrow-turn-down-left']"
      size="xs"
      class="tw-absolute tw-top-5 tw-right-3 -tw-translate-y-1/2 tw-text-gray-500"
    ></fa-icon>
  }
  @if (searching) {
    <fa-icon
      animation="spin"
      animation="spin"
      [icon]="['fal', 'spinner-third']"
      size="xs"
      class="tw-absolute tw-top-1/2 tw-right-3 -tw-translate-y-1/2 tw-text-gray-500"
    ></fa-icon>
  }

  <input
    type="text"
    class="tw-text-base tw-bg-gray-100 tw-h-9 tw-active:tw-bg-white tw-focus:tw-bg-white tw-rounded-md tw-w-full tw-pl-3 tw-pr-10 tw-py-2.5 tw-transition-all tw-outline-none"
    placeholder="Søg..."
    aria-label="Number"
    matInput
    [formControl]="searchControl"
    [matAutocomplete]="auto"
    [class.corners]="searchOpen || searchNoResults"
    (blur)="searchOpen = searchNoResults || false"
  />
</form>

<mat-autocomplete
  #auto="matAutocomplete"
  (opened)="searchOpen = true"
  (closed)="searchOpen = false"
  (optionSelected)="onOptionSelected($event.option.value.id)"
  [displayWith]="displayWith"
>
  @for (order of searchResults; track order) {
    <mat-option [value]="order" class="tw-leading-7">
      <div class="flex flex-col">
        {{ order.attributes.web_no }}
        <span class="mat-small mt-n2">{{ order.attributes.store_id | storeNameById }}</span>
      </div>
    </mat-option>
  }
</mat-autocomplete>

@if (searchNoResults) {
  <div
    class="tw-absolute tw-left-0 tw-bottom-0 tw-w-full tw-translate-y-full tw-bg-white tw-rounded-b-md tw-border tw-border-gray-100 tw-shadow-gray-100 tw-shadow-sm tw-p-3 tw-text-sm"
  >
    Ingen resultater
  </div>
}
