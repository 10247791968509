import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { SidenavComponent } from './sidenav.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [SidenavComponent],
  imports: [CommonModule, RouterModule, FontAwesomeModule, MatButtonModule, MatDividerModule, MatTooltipModule],
  exports: [SidenavComponent],
})
export class SidenavModule {}
