import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { DataObject, Stores, CompanyBootstrap } from '@app/_shared/interfaces';
import { getDefaultStore } from '@app/main-store/bootstrap.selector';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { Store, select } from '@ngrx/store';
import { Observable, takeUntil, map, Subject, EMPTY, tap } from 'rxjs';

export interface MenuItem {
  icon: IconName;
  title: string;
  route: string;
  dividerTop?: boolean;
  active?: boolean;
}

@Component({
  selector: 'sb-sidenav',
  templateUrl: './sidenav.component.html',
})
export class SidenavComponent implements OnInit, OnDestroy {
  @Input() menuItems: MenuItem[] = [];
  @Input() animate = true;
  @Input() hide = false;

  private componentDestroyed$: Subject<void> = new Subject();

  public activeStoreId: string | number = null;

  public defaultStore$: Observable<DataObject<Stores> | null> = EMPTY;

  @HostBinding('class')
  get hostClasses() {
    let classes = `tw-sticky tw-h-[calc(100dvh-theme("spacing.16"))] tw-h-full tw-bg-[white] tw-border-r tw-border-gray-100 tw-shadow-sm tw-shadow-gray-100 tw-z-20 tw-top-0 tw-group/menu tw-p-3 tw-overflow-hidden ${this.hide ? 'tw-w-0 !tw-p-0' : this.animate ? 'tw-w-16 hover:tw-w-52 tw-transition-all tw-duration-200 tw-ease-in-out' : 'tw-w-52'}`;
    return classes;
  }

  constructor(private rxStore: Store<any>) {}

  ngOnInit(): void {
    this.defaultStore$ = this.rxStore.pipe(
      takeUntil(this.componentDestroyed$),
      select(getDefaultStore),
      tap((store) => {
        const storeId = store?.id;
        this.activeStoreId = storeId;
      }),
    );
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
