<sb-toolbar [activeRouteGroup]="activeRouteGroup"></sb-toolbar>

<mat-sidenav-container [hasBackdrop]="slideInBackdrop" class="tw-w-full">
  <mat-sidenav
    [autoFocus]="slideInAutoFocus"
    [mode]="slideInMode"
    (closedStart)="onCloseSlideIn()"
    [position]="'end'"
    #sideNav
    [style.width]="slideInWidth"
    class="tw-bg-white !tw-border-l-gray-100"
  >
    @if (slideInPortal) {
      <ng-template [cdkPortalOutlet]="slideInPortal"></ng-template>
    }
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="tw-grid tw-grid-cols-[auto_1fr] tw-w-full">
      <sb-sidenav
        [hide]="activeRouteGroup !== 'accounting'"
        [animate]="activeRouteGroup !== 'accounting'"
        [menuItems]="sideNavItems"
      ></sb-sidenav>
      @if (activeRouteGroup !== 'accounting') {
        <div class="tw-h-[calc(100dvh-theme('spacing.16'))] tw-p-6 tw-overflow-auto tw-relative" cdkScrollable>
          <router-outlet></router-outlet>
        </div>
      }
      @if (activeRouteGroup === 'accounting') {
        <div class="tw-h-[calc(100dvh-theme('spacing.16'))] tw-px-6 tw-overflow-auto tw-relative">
          <nav mat-tab-nav-bar mat-stretch-tabs="false" mat-align-tabs="start" [tabPanel]="tabPanel">
            @for (item of tabGroupItems; track item) {
              <a
                mat-tab-link
                [routerLink]="[item.route]"
                (click)="setActiveRoute(item.route)"
                [active]="isActiveRoute(item.route)"
              >
                {{ item.label }}
              </a>
            }
          </nav>
          <mat-tab-nav-panel #tabPanel>
            <router-outlet></router-outlet>
          </mat-tab-nav-panel>
        </div>
      }
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
