import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseObject } from '@app/_shared/interfaces';
import { Company } from '@app/_shared/interfaces/http/statistics/company';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/statistics/companies';

  constructor(private _http: HttpClient) {}

  index(params?: HttpParams) {
    return this._http.get<ResponseObject<Company>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }
}
