import { LayoutActions } from './layout.actions';

// eslint-disable-next-line
export interface LayoutState {}

export const initialState: LayoutState = {};

export function layoutReducer(state = initialState, action: LayoutActions): LayoutState {
  switch (action.type) {
    default: {
      return state;
    }
  }
}
