import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  public isLoading = false;

  constructor(private http: HttpClient) {}

  public searchOrderByWebNo(storeId: string | number, queryProperty: string, queryValue: string) {
    const headers = new HttpLaravelHeaders();
    const params = new HttpParams().append('filter[store_id]', storeId).append(`filter[${queryProperty}]`, queryValue);
    return this.http.get('/api/v1/user/orders', { headers, params });
  }
}
