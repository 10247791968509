import { createAction, props } from '@ngrx/store';
import { DataList, ErrorObject } from '@app/_shared/interfaces';
import { FeatureFlag } from '@app/_shared/interfaces/http/companies/feature-flag';

export const loadCompanyFeatureFlags = createAction('[Feature flags] Load Company feature flags');
export const loadCompanyFeatureFlagsSuccess = createAction(
  '[Feature flags] Load Company feature flags success',
  props<{ response: DataList<FeatureFlag, any> }>(),
);
export const loadCompanyFeatureFlagsFailed = createAction(
  '[Feature flags] Load Company feature flags failed',
  props<{ error: ErrorObject }>(),
);
