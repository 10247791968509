import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackBarSuccessComponent } from '@app/_shared/snack-bars/snack-bar-success.component';
import { SnackBarFailureComponent } from '@app/_shared/snack-bars/snack-bar-failure.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FontAwesomeImportModule } from '@app/_shared/material/font-awesome-import.module';
import { SnackBarErrorsComponent } from './snack-bar-errors.component';
import { SnackBarNoticeComponent } from './snack-bar-notice.component';

@NgModule({
  declarations: [SnackBarSuccessComponent, SnackBarFailureComponent, SnackBarErrorsComponent, SnackBarNoticeComponent],
  imports: [CommonModule, MatIconModule, MatSnackBarModule, FontAwesomeImportModule],
})
export class SnackBarModule {}
