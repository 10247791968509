import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPipeModule } from '@app/_shared/pipes/custom-pipe.module';
import { FontAwesomeImportModule } from '@app/_shared/material/font-awesome-import.module';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppRoutingModule } from '@app/app-routing.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { SearchBarModule } from '../search-bar/search-bar.module';
import { ToolbarComponent } from './toolbar.component';

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    FontAwesomeImportModule,
    MatButtonModule,
    MatToolbarModule,
    MatMenuModule,
    MatTooltipModule,
    MatDividerModule,
    SearchBarModule,
    CustomPipeModule,
  ],
  exports: [ToolbarComponent],
})
export class ToolbarModule {}
