import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as BootstrapActions from '@app/main-store/bootstrap.actions';
import * as FeatureFlagsActions from '@app/main-store/feature-flags.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MainLayoutGuard {
  constructor(
    private _actions$: Actions,
    private _store: Store,
  ) {}

  canActivate(
    _activatedRouteSnapshot: ActivatedRouteSnapshot,
    _routerStateSnapshot: RouterStateSnapshot,
  ): Observable<boolean> {
    this._store.dispatch(BootstrapActions.loadCompanyBootstrap());
    this._store.dispatch(FeatureFlagsActions.loadCompanyFeatureFlags());

    return combineLatest([this.ofLoadCompanyBootstrap$()]).pipe(
      first(),
      map(() => true),
    );
  }

  private ofLoadCompanyBootstrap$ = () =>
    this._actions$.pipe(
      ofType(BootstrapActions.loadCompanyBootstrapSuccess, BootstrapActions.loadCompanyBootstrapFailed),
      first(),
    );
}
