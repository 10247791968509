export function customizeSentryBreadcrumbs(breadcrumb, hint) {
  if (!hint.event || !hint.event.target) {
    return breadcrumb;
  }

  const target = hint.event.target;
  const sentryIdElement = findSentryIdElement(target);
  const elementDescription = getElementDescription(target, sentryIdElement);

  if (breadcrumb.category === 'ui.click') {
    const clickText = handleClickEvent(target, sentryIdElement);
    breadcrumb.message = `${elementDescription}${clickText}`;
    return breadcrumb;
  }

  if (breadcrumb.category === 'ui.input') {
    const inputValue = handleInputEvent(target);
    breadcrumb.message = `${elementDescription}: ${inputValue}`;
    return breadcrumb;
  }

  return breadcrumb;
}

const findSentryIdElement = (element) => {
  while (element && element !== document.body) {
    if (element.dataset && element.dataset.sentryId) {
      return element;
    }
    element = element.parentElement;
  }
  return null;
};

const getElementDescription = (target, sentryIdElement) => {
  const targetTagName = target.tagName.toLowerCase();
  let elementDescription = sentryIdElement
    ? `${sentryIdElement.tagName.toLowerCase()}[data-sentry-id="${sentryIdElement.dataset.sentryId}"]`
    : targetTagName;

  if (sentryIdElement && sentryIdElement !== target) {
    elementDescription += ` > ${targetTagName}`;
  }

  const relevantAttributes = ['id', 'name', 'type'];
  relevantAttributes.forEach((attr) => {
    if (target.getAttribute(attr)) {
      elementDescription += `[${attr}="${target.getAttribute(attr)}"]`;
    }
  });

  return elementDescription;
};

const trimText = (text) => text.trim().slice(0, 20);

const handleClickEvent = (target, sentryIdElement) => {
  let text = trimText((sentryIdElement || target).innerText);

  if (target.classList.contains('mat-slide-toggle-input')) {
    const toggleElement = target.closest('mat-slide-toggle');
    const label = toggleElement.querySelector('.mat-slide-toggle-content');
    if (label) {
      text = trimText(label.textContent);
    }
  }

  return text ? `: ${text}` : '';
};

const handleInputEvent = (target) => {
  if (target.type === 'password') {
    return '*'.repeat(target.value.length);
  }
  return trimText(target.value);
};
