import { NgModule } from '@angular/core';
import { SearchBarComponent } from './search-bar.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomPipeModule } from '@app/_shared/pipes/custom-pipe.module';
import { FontAwesomeImportModule } from '@app/_shared/material/font-awesome-import.module';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [SearchBarComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomPipeModule,
    FontAwesomeImportModule,
    MatButtonModule,
    MatTooltipModule,
    MatAutocompleteModule,
  ],
  exports: [SearchBarComponent],
})
export class SearchBarModule {}
