import { Injectable } from '@angular/core';
import * as FeatureFlagsActions from '@app/main-store/feature-flags.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CompanyFeatureFlagsService } from '@app/_shared/service/http/companies/company-feature-flags.service';

@Injectable()
export class FeatureFlagsEffects {
  loadCompanyFeatureFlags$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FeatureFlagsActions.loadCompanyFeatureFlags),
      switchMap(() => {
        return this._companyFeatureFlagsService.index().pipe(
          map((data) => FeatureFlagsActions.loadCompanyFeatureFlagsSuccess({ response: data })),
          catchError((err) => of(FeatureFlagsActions.loadCompanyFeatureFlagsFailed({ error: err.error }))),
        );
      }),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _companyFeatureFlagsService: CompanyFeatureFlagsService,
  ) {}
}
