/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { customizeSentryBreadcrumbs } from '@app/_shared/support/functions/customizeSentryBreadcrumbs';
import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';

Sentry.init({
  beforeBreadcrumb: customizeSentryBreadcrumbs,
  dsn: environment.sentry,
  environment: environment.environment,
  release: `${environment.version}`,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
